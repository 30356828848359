import React from 'react';
import CatoImage from '../cato-image';
import CatoLink from '../cato-link';
// import CatoButton from '../cato-button';
import {ReactComponent as Play} from '../../assets/images/masterclass/play icon - normal.svg';
import './style.scss';


const ARTICLE_TYPE_MAP = {
    webinar: 'Webinar',
    blog: 'Blog'
}

const LinkBox = ({title,
                     img,
                     path,
                     summary,
                     featured=false,
                     type=null,
                     recommended=false,
                     showTypeLabel=true,
                     hoverExpand=false,
                     buttonLabel='Download',
                     excerptLimit=18
                 }) => {
    const summaryArr = summary.split(' ');
    const excerpt = summaryArr.length > excerptLimit ? `${summaryArr.slice(0, excerptLimit).join(' ')}...` : summary;


    return (
        <article className={`link-box ${featured ? 'featured' : ''} ${recommended ? 'recommended' : ''} ${hoverExpand ? 'hover-expand' : ''}`}>
            <CatoLink path={path}>
                <div className="image-wrapper">
                    <CatoImage img={img} />
                    {((type && (type === 'Webinar' || type === 'Webinar On Demand' || type === 'Demo')) || featured ) && <Play/>}
                </div>
                <div className="text-wrapper">
                    {type && showTypeLabel && <span className="type">{ARTICLE_TYPE_MAP[type]}</span>}
                    <h3>{title}</h3>
                    <p>{excerpt}</p>
                </div>
                {hoverExpand && <button className="button">{buttonLabel}</button>}
            </CatoLink>
            {hoverExpand && <div className="offset"/>}
        </article>
    )
}

export default LinkBox;