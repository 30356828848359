import React from 'react';
import {graphql} from "gatsby";
import Layout from '../components/layout';
import CatoImage from '../components/cato-image';
import LinkBox from '../components/resource-link-box-with-tag';
import {LANG} from "../common/consts";
import Implementing from '../assets/images/originals/1200X628_Implementing SASE in the Global Enterprise .png';
import Introducing from '../assets/images/originals/1200X628_Introducing SASE.png';
import Using from '../assets/images/originals/1200X628_Using SASE For ZTNA.png';
import '../assets/styles/pages/originals.scss';

const Originals = ({location, data, pageContext}) => {
    const {wpPage} = data;
    const {originalsSeriesFields} = wpPage;

    return (
        <Layout
            className="originals"
            yoastData={wpPage.seo || null}
            seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
            lang={pageContext.lang}
            whiteMenu={false}
            seoPath={location.pathname}
            translations={pageContext.translations}
            transparentMenu={true}
        >
            <div className="content-wrap">
                <div className="background-wrapper">
                    <div className="background"/>
                <section className="top">
                    {originalsSeriesFields.headline && <h1>{originalsSeriesFields.headline}</h1>}
                    {originalsSeriesFields.subheading && <h2>{originalsSeriesFields.subheading}</h2>}
                    {originalsSeriesFields.logos?.length && <div className="logos">
                        {originalsSeriesFields.logos.map((logo, i) => <CatoImage img={logo.logo} key={`originals-logo-${i}`}/>)}
                    </div>
                    }
                    <ul className="speakers">
                        {originalsSeriesFields.speakerList?.length &&
                            originalsSeriesFields.speakerList.map((speaker, i) => (
                                <li key={`original-speaker-${i}`}>
                                    {speaker.speakerImage && <CatoImage img={speaker.speakerImage}/>}
                                    <div className="text">
                                        {speaker.name && <strong className="name">{speaker.name}</strong>}
                                        {speaker.position && <span className="title">{speaker.position}</span>}
                                        {speaker.company && <strong className="company">{speaker.company}</strong>}
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                    {originalsSeriesFields.description && <p>{originalsSeriesFields.description}</p>}
                </section>
                <section className="articles">
                    {originalsSeriesFields.resourcesList?.length && originalsSeriesFields.resourcesList.map((item, i) => {
                        const title = item.title ? item.title : item.resource ? item.resource[0].title : null;
                        const img = item.image ? item.image : item.resource ? item.resource[0].resourceFields.resourceSmallImage : null;
                        const summary = item.excerpt ? item.excerpt : item.resource ? item.resource[0].resourceFields.resourceShortDescription : null;
                        const path = item.link ? item.link : item.resource ? item.resource[0].resourceFields.resourceLink : null;

                        return (<LinkBox
                            title={title}
                            summary={summary}
                            img={img}
                            showTypeLabel={false}
                            hoverExpand={false}
                            path={path}
                            recommended={item.recommended || false}
                            type={'Webinar'}
                            key={`originals-article-${i}`}
                            excerptLimit={15}
                        />)
                    })}
                </section>
                </div>
            </div>
        </Layout>
    )
}

export default Originals;

export const query = graphql`
    query originalsSeriesPageQuery($id: String!) {
        wpPage(id: { eq: $id}) {
            title
            slug
            title
            content
            slug
            id
            date(formatString: "MMMM DD, YYYY")
            ...YoastData
            path: uri
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            originalsSeriesFields {
                headline
                subheading
                logos {
                    logo {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                }
                speakerList {
                    speakerImage {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                    name
                    position
                    company
                }
                description
                resourcesList {
                    image {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                    title
                    excerpt
                    link
                    recommended
                    resource {
                        ... on WpResource {
                            id
                            title
                            resourceFields {
                                resourceShortDescription
                                resourceSmallImage {
                                    altText
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 340) {
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                        extension
                                        publicURL
                                    }
                                }
                                resourceLink
                            }
                        }
                    }
                }
                
            }
        }
        
    }
`